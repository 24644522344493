exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-menu-bakery-js": () => import("./../../../src/pages/menu/bakery.js" /* webpackChunkName: "component---src-pages-menu-bakery-js" */),
  "component---src-pages-menu-drink-js": () => import("./../../../src/pages/menu/drink.js" /* webpackChunkName: "component---src-pages-menu-drink-js" */),
  "component---src-pages-menu-favorite-js": () => import("./../../../src/pages/menu/favorite.js" /* webpackChunkName: "component---src-pages-menu-favorite-js" */),
  "component---src-pages-menu-food-js": () => import("./../../../src/pages/menu/food.js" /* webpackChunkName: "component---src-pages-menu-food-js" */),
  "component---src-pages-menu-index-js": () => import("./../../../src/pages/menu/index.js" /* webpackChunkName: "component---src-pages-menu-index-js" */),
  "component---src-pages-product-coffeebeans-js": () => import("./../../../src/pages/product/coffeebeans.js" /* webpackChunkName: "component---src-pages-product-coffeebeans-js" */),
  "component---src-pages-product-index-js": () => import("./../../../src/pages/product/index.js" /* webpackChunkName: "component---src-pages-product-index-js" */),
  "component---src-pages-product-merch-js": () => import("./../../../src/pages/product/merch.js" /* webpackChunkName: "component---src-pages-product-merch-js" */),
  "component---src-pages-story-js": () => import("./../../../src/pages/story.js" /* webpackChunkName: "component---src-pages-story-js" */)
}

